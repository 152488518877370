//import '@babel/polyfill'
import 'mutationobserver-shim' 
import Raphael from 'raphael/raphael'
global.Raphael = Raphael
import Vue from 'vue'
import './plugins'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueMeta from 'vue-meta'
import './directives'

Vue.config.productionTip = false 
Vue.prototype.$http = axios

const token = localStorage.getItem('token')

if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer '+token
}

Vue.use(VueMeta)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
