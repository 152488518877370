import Vue from 'vue'
import Vuex from 'vuex'
import * as procloud from "@/store/modules/procloud.js";
import * as intelinsuranceApi from "@/store/modules/IntelInsuranceApi.js";

Vue.use(Vuex)

export default new Vuex.Store({
  
  state: {
    appName: ' proCloud',
    logo: require('../assets/images/logo.png'),
    darklogo:require('../assets/images/logo-white.png'),
    mode: {
      //dark: localStorage.getItem('dark') === 'false' ? false : true,
      dark: false,
      //rtl: localStorage.getItem('rtl') === 'false' ? false : true,
      rtl: false
    },
    namespaced: true,
    user:{
      name:'Bill Yerds',
      image:require('../assets/images/user/1.jpg'),
    },
    token: intelinsuranceApi.getters.token,
    lifeToken: intelinsuranceApi.getters.lifeToken,
    baseUrl: 'http://intelinsurance-panel.procloud.com.ar'
  },
  mutations: {
    
    layoutModeCommit (state, payload) {
      
      if (payload.dark !== undefined) {
        state.mode.dark = payload.dark
        localStorage.setItem('dark', payload.dark)
        const body = document.querySelector('body')
        if (payload.dark) {
          state.logo = require('../assets/images/logo-white.png')
          body.classList.add('dark')
        } else {
          state.logo = require('../assets/images/logo.png')
          body.classList.remove('dark')
        }
      }
      
      if(payload.rtl !== undefined) {
        
        state.mode.rtl = payload.rtl
        localStorage.setItem('rtl', payload.rtl)
        if (payload.rtl) {
            document.body.parentElement.setAttribute('dir','rtl')
        } else {
            document.body.parentElement.setAttribute('dir','ltr')
        }
      }
    }
  },
  actions: {
    layoutModeAction (context, payload) {
      context.commit('layoutModeCommit', payload)
    }
  },
  getters: {
    appName: state => { return state.appName },
    baseUrl: state => { return state.baseUrl },
    logo: state => { return state.logo },
    darklogo: state => { return state.darklogo },
    image1: state => { return state.user.image },
    name: state => { return state.user.name },
    image2:state => { return state.user.image2},
    image3:state => { return state.user.image3},
    dark: state => { return state.mode.dark },
    rtl:  state => { return state.mode.rtl },
    getToken (state) {
      return state.token
    },
    getLifeToken (state) {
      return state.lifeToken
    },
    wookieMembership (state) {
      return state.wookieMembership
    }
  },
  modules: {
    procloud,
    intelinsuranceApi
  }
})
