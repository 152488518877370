import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

//Adding layouts router.
const BlankLayout = () => import("@/layouts/BlankLayout")
const Layout1 = () => import("@/layouts/backend/Layout-1")

//Adding page content router.
const Dashbord1 = () => import('@/views/backend/Dashboards/Dashbord1')
const Simple = () => import('@/views/backend/Forms/Form Wizard/Simple')


const FilterResult = () => import('@/views/backend/Results/Filter')
const Coeficientes = () => import('@/views/backend/Results/IndicesCoberturas')
const Statistics = () => import('@/views/backend/Statistics/Statistics')

//const Soporte = () =>import('@/views/backend/Soporte/Soporte')

//auth elements
const SignIn = () => import('@/views/backend/Auth/SignIn')
//const SignUp = () => import('@/views/backend/Auth/SignUp')
const Home = () => import('@/views/backend/Pages/Home')
//const RecoverPassword = () => import('@/views/backend/Auth/RecoverPassword')
//const LockScreen = () => import('@/views/backend/Auth/LockScreen')
//const ConfirmMail = () => import('@/views/backend/Auth/ConfirmMail')

//const Accountsetting =() => import('@/views/backend/App/Extraap/AccountSettings')
//const privacypolicy = () => import('@/views/backend/App/Extraap/PrivacyPolicy')
//const PrivacySettings = () =>import('@/views/backend/App/Extraap/PrivacySettings')
//const TermsOfUse = () =>import('@/views/backend/App/Extraap/TermsOfUse')

Vue.use(VueRouter)

const childRoute = () => [
  {
    path: '',
    name: 'layout.dashboard1',
    meta: { name: 'Dashboard1', requiresAuth: true, title: 'Benchmarking | Panel' },
    component: Dashbord1
  },
  {
    path: 'resultados/filtro',
    name: 'app.ResultsFilter',
    meta: { name: 'Benchmarking | Resultados | Filtro', requiresAuth: true },
    component: FilterResult
  },
  {
    path: 'resultados/coeficientes',
    name: 'app.CoeficientesFilter',
    meta: { name: 'Benchmarking | Resultados | Coeficientes', requiresAuth: true },
    component: Coeficientes
  },
  {
    path: 'estadisticas',
    name: 'app.StatisticsFilter',
    meta: { name: 'Benchmarking | Estadísticas | Filtro', requiresAuth: true },
    component: Statistics
  }
]

const authchildRoute = () => [
  {
    path: 'sign-in',
    name: 'auth.login',
    meta: { name: 'SignIn', guest: true },
    component: SignIn
  },
  {
    path: 'ingresar',
    name: 'auth.ingresar',
    meta: { name: 'SignIn', guest: true },
    component: SignIn
  },
  {
    path: 'hola',
    name: 'home',
    meta: { name: 'Home', guest: true },
    component: Home
  },
  {
    path: 'orbis',
    name: 'home',
    meta: { name: 'Home', guest: true },
    component: Home
  }

]

const routes = [
  {
    path: '/',
    name: '',
    component: Layout1,
    children: childRoute()
  },
  {
    path: '/auth',
    name: 'auth',
    component: BlankLayout,
    children: authchildRoute()
  },
  {
    path: '/hola',
    name: 'hola',
    component: Home,
    meta: { title: 'proCloud | Benchmarking' }
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  if (to.matched.some(record => record.meta.requiresAuth)) {

    if (localStorage.getItem('token') == null) {
      next({
        path: '/hola',
        params: { nextUrl: to.fullPath }
      })
    } else {


      var lifeToken = store.getters.getLifeToken()
      var token = store.getters.getToken()

      if (Math.floor((new Date() - lifeToken) / 60000) > 50 && Math.floor((new Date() - lifeToken) / 60000) < 55) {
        console.log('paso 50 minutos');
        var data = { token: token }
        store.dispatch('intelinsuranceApi/refresh', data).then(resp => { }).catch(err => console.log(err))
      } else if (Math.floor((new Date() - lifeToken) / 60000) >= 55) {
        store.dispatch('intelinsuranceApi/logout', data).then(resp => { location.reload() }).catch(err => console.log(err))
        next({
          path: '/hola',
          params: { nextUrl: to.fullPath }
        })
      }
      //let user = JSON.parse(localStorage.getItem('user'))
      let user = localStorage.getItem('user')
      if (to.matched.some(record => record.meta.is_admin)) {
        if (user.is_admin == 1) {
          next()
        } else {
          next({ name: 'layout.dashboard1' })
        }
      } else {
        next()
      }
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    if (localStorage.getItem('token') == null) {
      next()
    } else {
      next({ name: 'layout.dashboard1' })
    }
  } else {
    next()
  }
})


export default router
