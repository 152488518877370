import axios from "axios";
//import Nprogress from 'nprogress'

const apiClient = axios.create({
  //baseURL: "https://cdn-multicotizadores.procloud.com.ar",
  baseURL: "http://localhost:8055",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  },
  timeout: 1000000
});

/*apiClient.interceptors.request.use(config => {
  Nprogress.start()
  return config
})

apiClient.interceptors.response.use(response => {
  Nprogress.done()
  return response
})*/

export default {
  signIn() {
    return apiClient.get("/js/static/coberturas/coberturas.json?v=2");
  },
  signUp(data) {
    return apiClient.post("/js/static/localidades/", data);
  } 
};
