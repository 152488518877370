import axios from "axios";
//import Nprogress from 'nprogress'

const apiClient = axios.create({
  baseURL: "https://cdn-multicotizadores.procloud.com.ar",
  // baseURL: "http://procloud-cdn-multicotizador.ba",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  },
  timeout: 1000000
});

/*apiClient.interceptors.request.use(config => {
  Nprogress.start()
  return config
})

apiClient.interceptors.response.use(response => {
  Nprogress.done()
  return response
})*/

export default {
  getCoberturas() {
    return apiClient.get("/js/static/coberturas/coberturas.json?v=3");
  },
  getLocalidades(provinciaId) {
    return apiClient.get("/js/static/localidades/" + provinciaId + ".json");
  },
  getQuotes(data) {
    return apiClient.post("/api/multicotizar/auto", data);
  },
  getBenchMarkingConfigurationList() {
    return apiClient.get("/api/benchmarking/getConfigurationList");
  },
  getConfigurationCars(data) {
    return apiClient.post("/api/benchmarking/getConfigurationCars", data);
  },
  getConfigurationData(data) {
    return apiClient.post("/api/benchmarking/getConfigurationData", data);
  },
  getConfigurationResult(data) {
    return apiClient.post("/api/benchmarking/getConfigurationResult", data);
  },
  getConfigurationStatistics(data) {
    return apiClient.post("/api/benchmarking/getConfigurationStatistics", data);
  },
  getConfigurationPlaces(data) {
    return apiClient.post("/api/benchmarking/getConfigurationPlaces", data);
  },
  getConfigurationCompanies(data) {
    return apiClient.post("/api/benchmarking/getConfigurationCompanies", data);
  },
  getConfigurationResutls(data) {
    return apiClient.post("/api/benchmarking/getConfigurationPlaces", data);
  },
  getCarsHistory(data) {
    return apiClient.post("/api/benchmarking/getCarsHistory", data);
  },
  getIndiceByQuotes(data) {
    return apiClient.post("/api/benchmarking/functions/statistics/get-by-quotes", data);
  }
};
