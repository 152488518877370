import WookieServices from "@/services/WookieServices.js";
import axios from "axios";
import config from '@/config/variables'

//let BASE_URL = 'http://phpstack-685737-2515809.cloudwaysapps.com/panel/public/wookie';
//let BASE_URL = 'https://wookie.app/panel/public/wookie';
//let BASE_URL = '/panel/public/wookie';
//let BASE_URL = 'https://panel.wookie.app/wookie';
let BASE_URL = config.basePath;

export const namespaced = true;

export const state = {
    status: '',
    token: localStorage.getItem('token') || '',
    user: JSON.parse(localStorage.getItem('user')) || {},
    lifeToken: localStorage.getItem('lifeToken') || '',
    //user: {}
};
export const mutations = {
    auth_request(state) {
        state.status = 'loading'
    },
    auth_success(state, payload) {
        state.status = 'success'
        state.token = payload.token
        state.user = payload.user
        state.lifeToken = payload.lifeToken
    },
    auth_error(state) {
        state.status = 'error'
    },
    logout(state) {
        state.status = ''
        state.token = ''
    },
};
export const actions = {
    sendMail({commit}, data){
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('token');
            axios({ url: BASE_URL+'/mail', data: data, method: 'POST',headers: { Authorization: `Bearer ${token}`} })
            .then(resp => {
                console.log(resp);
                resolve(resp)
            }).catch(err => {
                console.lof(err);
                resolve()
            })
        })
    },
    logout({commit}) {
        return new Promise((resolve, reject) => {
          commit('logout')
          localStorage.removeItem('token')
          localStorage.removeItem('lifeToken')
          localStorage.removeItem('user')
          delete axios.defaults.headers.common['Authorization']
          resolve()
        })
    },
    refresh({commit}, token) {
        return new Promise((resolve, reject) => {
            commit('auth_request')
            //user.mode = 'cookie';
            axios({ url: BASE_URL+'/auth/refresh', data: token, method: 'POST' })
            .then(resp => {
                const token = resp.data.data.token
                localStorage.setItem('token', token)
                localStorage.setItem('lifeToken', Date.now())
                commit('auth_success', { token: token, lifeToken: Date.now() })
                resolve(resp)
            }).catch(err => {
                commit('logout')
                localStorage.removeItem('token')
                localStorage.removeItem('lifeToken')
                localStorage.removeItem('user')                
                delete axios.defaults.headers.common['Authorization']
                location.reload();
                resolve()
            })
        })
    },
    login({commit}, user) {
        return new Promise((resolve, reject) => {
            commit('auth_request')
            //user.mode = 'cookie';
            axios({ url: BASE_URL+'/auth/authenticate', data: user, method: 'POST' })
            .then(resp => {
                const token = resp.data.data.token
                localStorage.setItem('token', token)
                localStorage.setItem('lifeToken', Date.now())
                const user = resp.data.data.user
                localStorage.setItem('user', JSON.stringify(user))
                commit('auth_success', { token: token, user: user, lifeToken: Date.now() })
                resolve(resp)
                //axios.defaults.headers.common['Authorization'] = token
                /*axios({ url: 'http://phpstack-685737-2515809.cloudwaysapps.com/users/me', data: user, method: 'GET', headers: { Authorization: `Bearer ${token}` } }).then(respuser => {
                    console.log(respuser);
                    const user = respuser.data.data
                    localStorage.setItem('user', JSON.stringify(user))
                    commit('auth_success', { token: token, user: user })
                    resolve(respuser)
                }) .catch(err => {
                    localStorage.removeItem('user')
                    commit('auth_error')                    
                    reject(err)
                })*/
            })
            .catch(err => {
                commit('auth_error')
                localStorage.removeItem('token')
                localStorage.removeItem('lifeToken')
                localStorage.removeItem('user')
                reject(err)
            })
        })
    },
    sendEmail({commit}, user) {
        const token = localStorage.getItem('token');
        return new Promise((resolve, reject) => {          
            var emailData = {
                "to": [
                    user.email,
                    "fede@blowapp.com.ar"
                ],
                "subject": "proCloud | Benchmarking | Panel",
                "body": "Hola <b>{{name}}</b>, gracias por registrarte en Wookie.<br>Tus datos de acceso son: {{password}}.",
                "type": "html",
                "data": {
                    "name": user.first_name+" "+user.last_name,
                    "mail": user.email,
                    "password": user.password
                }
            };              

            axios({ url: BASE_URL+'/mail', data: emailData, method: 'POST',headers: { Authorization: `Bearer ${token}`} })
            .then(resp => {
                resolve(resp)
            })
            .catch(err => {
                console.log('ERROR');
                console.log(err);
                commit('auth_error')
                localStorage.removeItem('user')
                localStorage.removeItem('token')
                reject(err)
            })                
        })
    },  
    register({commit}, user) {
        return new Promise((resolve, reject) => {
          commit('auth_request')
          axios({ url: BASE_URL+'/users', data: user, method: 'POST'})
          .then(resp => {
            axios({ url: BASE_URL+'/auth/authenticate', data: user, method: 'POST' })
            .then(resp => {
                const token = resp.data.data.token
                localStorage.setItem('token', token)
                localStorage.setItem('lifeToken', Date.now())
                const user = resp.data.data.user
                localStorage.setItem('user', JSON.stringify(user))
                commit('auth_success', { token: token, user: user, lifeToken: Date.now() })
                resp.user = user;
                resolve(resp)
            })
            .catch(err => {
                commit('auth_error')
                localStorage.removeItem('user')
                localStorage.removeItem('token')
                reject(err)
            })
          })
          .catch(err => {
            if( err.response ){
                console.log(err.response.data); // => the response payload 
            }
            commit('auth_error', err)
            localStorage.removeItem('user')
            localStorage.removeItem('token')
            reject(err)
          })
        })
      }
};
export const getters = {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    userData: state => state.user,
    token: (state) => {
        return localStorage.getItem('token')        
    },
    lifeToken: (state) => {
        return localStorage.getItem('lifeToken')        
    }
  /*getSculptureById: (state) => (id) => {
    return state.sculptures.find((sculpture) => sculpture.id === id);
  },*/
};
