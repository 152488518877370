import ProCloudServices from "@/services/ProCloudServices.js";

export const namespaced = true;

export const state = {
  quotes: [],
  localidades: [],
  coberturas: [],
  benchkmarking_configurations: [],
  benchkmarking_configuration_list: [],
  benchkmarking_configuration_cars: [],
  benchkmarking_configuration_places: [],
  benchkmarking_configuration_data: [],
  benchkmarking_configuration_result: [],
  benchkmarking_configuration_statistics: [],
  benchkmarking_cars_history: []

};
export const mutations = {
  GET_COBERTURAS(state, coberturas) {
    state.coberturas = coberturas;
  },
  GET_LOCALIDADES(state, localidades) {
    state.localidades = localidades;
  },
  GET_QUOTES(state, quotes) {
    state.quotes = quotes;
  },
  SET_BENCHMARKING_CONFIGURATION(state, configuration) {
    state.benchkmarking_configurations.push = configuration;
  },
  GET_BENCHMARKING_CONFIGURATION_LIST(state, configuration_list) {
    state.benchkmarking_configuration_list = configuration_list;
  },
  GET_BENCHMARKING_CONFIGURATION_PLACES(state, configuration_places) {
    state.benchkmarking_configuration_places = configuration_places;
  },
  GET_BENCHMARKING_CONFIGURATION_CARS(state, configuration_cars) {
    state.benchkmarking_configuration_cars = configuration_cars;
  },
  GET_BENCHMARKING_CONFIGURATION_DATA(state, configuration_data) {
    state.benchkmarking_configuration_data = configuration_data;
  },
  GET_BENCHMARKING_CONFIGURATION_RESULT(state, configuration_result) {
    state.benchkmarking_configuration_result = configuration_result;
  },
  GET_BENCHMARKING_CONFIGURATION_STATISTICS(state, configuration_statistics) {
    state.benchkmarking_configuration_statistics = configuration_statistics;
  },
  GET_BENCHMARKING_CARS_HISTORY(state, cars_history) {
    state.benchkmarking_cars_history = cars_history;
  },
  GET_BENCHMARKING_INDICE_QUOTES(state, indice_quotes) {
    state.benchkmarking_indice_quotes = indice_quotes;
  }
};
export const actions = {
  createConfiguration({ commit, dispatch }, data) {
    console.log(data);
    return ProCloudServices.setBenchMarkingConfiguration(data)
      .then(response => {
        commit('SET_BENCHMARKING_CONFIGURATION', response)
        const notification = {
          type: 'success',
          message: 'Todo perfecto!'
        }
        dispatch('notification/add', notification, { root: true })
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'Tuvimos un problema al traer eventos: ' + error.message
        }
        dispatch('notification/add', notification, { root: true })
        throw error
      })
  },
  fetchConfigurationList({ commit, dispatch }) {
    return ProCloudServices.getBenchMarkingConfigurationList()
      .then(response => {
        commit('GET_BENCHMARKING_CONFIGURATION_LIST', response.data)
        const notification = {
          type: 'success',
          message: 'Todo perfecto!'
        }
        //dispatch('notification/add', notification, { root: true })
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'Tuvimos un problema al traer eventos: ' + error.message
        }
        //dispatch('notification/add', notification, { root: true })
        throw error
      })
  },
  fetchConfigurationPlaces({ commit, dispatch }, data) {

    var postata = {
      'configurationId': data
    }
    return ProCloudServices.getConfigurationPlaces(postata)
      .then(response => {
        commit('GET_BENCHMARKING_CONFIGURATION_PLACES', response.data)
        const notification = {
          type: 'success',
          message: 'Todo perfecto!'
        }
        //dispatch('notification/add', notification, { root: true })
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'Tuvimos un problema al traer eventos: ' + error.message
        }
        //dispatch('notification/add', notification, { root: true })
        throw error
      })
  },
  fetchConfigurationData({ commit, dispatch }, data) {

    var postata = {
      'configurationId': data
    }
    return ProCloudServices.getConfigurationData(postata)
      .then(response => {
        commit('GET_BENCHMARKING_CONFIGURATION_DATA', response.data)
        const notification = {
          type: 'success',
          message: 'Todo perfecto!'
        }
        //dispatch('notification/add', notification, { root: true })
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'Tuvimos un problema al traer eventos: ' + error.message
        }
        //dispatch('notification/add', notification, { root: true })
        throw error
      })
  },
  fetchConfigurationResult({ commit, dispatch }, data) {


    return ProCloudServices.getConfigurationResult(data)
      .then(response => {
        commit('GET_BENCHMARKING_CONFIGURATION_RESULT', response.data)
        const notification = {
          type: 'success',
          message: 'Todo perfecto!'
        }
        //dispatch('notification/add', notification, { root: true })
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'Tuvimos un problema al traer eventos: ' + error.message
        }
        //dispatch('notification/add', notification, { root: true })
        throw error
      })
  },
  fetchCarsHistory({ commit, dispatch }, data) {


    return ProCloudServices.getCarsHistory(data)
      .then(response => {
        commit('GET_BENCHMARKING_CARS_HISTORY', response.data)
        const notification = {
          type: 'success',
          message: 'Todo perfecto!'
        }
        //dispatch('notification/add', notification, { root: true })
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'Tuvimos un problema al traer eventos: ' + error.message
        }
        //dispatch('notification/add', notification, { root: true })
        throw error
      })
  },
  fetchIndiceByQuotes({ commit, dispatch }, data) {


    return ProCloudServices.getIndiceByQuotes(data)
      .then(response => {
        commit('GET_BENCHMARKING_INDICE_QUOTES', response.data)
        const notification = {
          type: 'success',
          message: 'Todo perfecto!'
        }
        //dispatch('notification/add', notification, { root: true })
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'Tuvimos un problema al traer eventos: ' + error.message
        }
        //dispatch('notification/add', notification, { root: true })
        throw error
      })
  },
  fetchConfigurationStatistics({ commit, dispatch }, data) {


    return ProCloudServices.getConfigurationStatistics(data)
      .then(response => {
        commit('GET_BENCHMARKING_CONFIGURATION_STATISTICS', response.data)
        const notification = {
          type: 'success',
          message: 'Todo perfecto!'
        }
        //dispatch('notification/add', notification, { root: true })
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'Tuvimos un problema al traer eventos: ' + error.message
        }
        //dispatch('notification/add', notification, { root: true })
        throw error
      })
  },
  fetchLocalidades({ commit, dispatch }, provinciaId) {
    console.log(provinciaId);
    return ProCloudServices.getLocalidades(provinciaId)
      .then(response => {
        //commit("SET_TOTAL_SCULPTURES", response.headers["x-total-count"]);
        commit("GET_LOCALIDADES", response.data);
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Tuvimos un problema al traer eventos: " + error.message
        };
        dispatch("notification/add", notification, { root: true });
      });
  },
  fetchCoberturas({ commit, dispatch }, aseguradora) {
    console.log(aseguradora);
    return ProCloudServices.getCoberturas()
      .then(response => {
        //commit("SET_TOTAL_SCULPTURES", response.headers["x-total-count"]);
        commit("GET_COBERTURAS", response.data);
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Tuvimos un problema al traer eventos: " + error.message
        };
        dispatch("notification/add", notification, { root: true });
      });
  },
  fetchQuotes({ commit, dispatch }, data) {
    console.log(data);
    return ProCloudServices.getQuotes(data)
      .then(response => {
        //commit("SET_TOTAL_SCULPTURES", response.headers["x-total-count"]);
        commit("GET_QUOTES", response.data);
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Tuvimos un problema al traer eventos: " + error.message
        };
        dispatch("notification/add", notification, { root: true });
      });
  }
};
export const getters = {
  /*getSculptureById: (state) => (id) => {
    return state.sculptures.find((sculpture) => sculpture.id === id);
  },*/
};
