<template>
  <router-view/>
</template>
<script>
// import 'line-awesome/dist/line-awesome/css/line-awesome.min.css'
// import 'remixicon/fonts/remixicon.css'
import store from '@/store'
export default {
  name: 'App',
  metaInfo: {
    title: 'proCloud',
    titleTemplate: '%s | Bienvenido a IntelInsurance'
  },
  components: {
     isLoggedIn: function() { return this.$store.getters.IntelinsuranceApi.isLoggedIn },
     userData: function() { return this.$store.getters.IntelinsuranceApi.userData }
  },
  methods: {
    logout: function() {
      this.$store.dispatch('logout')
      .then(() => {
        this.$router.push('/login')
      })
    },
    checkLogin() {
      var lifeToken = store.getters.getLifeToken()
      
      var token = store.getters.getToken()
      if ( Math.floor((new Date() - lifeToken)/60000) >= 55) {
        store.dispatch('intelinsuranceApi/logout')
        .then(resp => {
          //
          console.log(this.$route.fullPath)
          if (this.$route.fullPath != '/' && this.$route.fullPath != '/hola' 
              && this.$route.fullPath != '/usuario/registrar'
              && this.$route.fullPath != '/usuario/ingresar'
              && this.$route.fullPath != '/auth/sing-in'
              && this.$route.fullPath != '/auth/sign-up'){
            //location.reload()            
          } else {
            console.log("pantalla para mostrar")
          }
        })
        .catch(err => console.log(err))
        
      }
    }
  },
  mounted () {
    // Intervalo que chequeas si estas logueado o no
    setInterval(this.checkLogin, 30000);
  }
}
</script>
<style lang="scss">
  @import "./assets/scss/backend.scss";
  @import "./assets/css/custom.css";
</style>
